import React from "react";
import EditOutlined from "@mui/icons-material/EditOutlined";
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from "@mui/material";
import axiosInstance from "../../api/axios/axiosInstance";
import PetQCheckCreateModal from "./PetQCheckCreateModal";

const EditPetQCheckButton = ({ selectedRule, updateParentState, isupdateTable }) => {
    const [isUpdateRuleModalOpen, setIsUpdateRuleModalOpen] = React.useState(false);

    // const deletePetQCheckRule = () => {
    //     var bodyFormData = new FormData();
    // }

    return (
        <>
            <Button
                onClick={() => setIsUpdateRuleModalOpen(true)}
                startIcon={<EditOutlined />}
            >
            </Button>

            {/* <Button
                onClick={() => deletePetQCheckRule()}
                startIcon={<DeleteIcon />}
            >
            </Button> */}

            <PetQCheckCreateModal
                isModalOpen={isUpdateRuleModalOpen}
                closeModal={() => {
                    setIsUpdateRuleModalOpen(false);
                    updateParentState(isupdateTable + 1);
                }}
                selectedRule={selectedRule}
            />
        </>
    );
};

export default EditPetQCheckButton;
