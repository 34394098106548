import React, { useCallback, useRef } from "react";
import {
    Box, CircularProgress, Button, TableContainer, Table, TableBody, TableCell, TableHead,
    TableRow, Typography, Paper, Tooltip
} from "@mui/material";

import { styled } from "@mui/system";
import { Edit as EditIcon } from "@mui/icons-material";
import CatFeederEventLabelModal from "../../components/Modules/CatFeederSingleView/CatFeederEventLabelModal";
import axiosInstance from "../../api/axios/axiosInstance";
import ImageViewer from 'react-simple-image-viewer';
import { toast, ToastContainer, Bounce } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadIcon from '@mui/icons-material/FileUpload';

const SearchBar = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
}));

const HeaderButton = styled(Button)(({ theme }) => ({
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    '&:hover': {
        backgroundColor: theme.palette.grey[800],
    },
    borderRadius: theme.shape.borderRadius,
}));

const TableHeaderCell = styled(TableCell)(({ theme }) => ({
    fontWeight: 'bold',
    backgroundColor: theme.palette.grey[200],
}));

const CatLink = ({ name, cat_id }) => {
    return (
        <a href={`/cats/${cat_id}`} target="_blank" rel="noopener noreferrer">
            {name}
        </a>
    );
};

const CatFeederLabelsAndClassifications = ({ eventId, cloud_video_file_url, firmware_version, newData }) => {


    const [labelData, setlabelData] = React.useState();
    const [isLoading, setIsLoading] = React.useState(true);
    const [videoURL, setVideoURL] = React.useState();
    const [imageURL, setImageURL] = React.useState([]);
    const [merror, setError] = React.useState();
    const [isLoadingCSV, setisLoadingCSV] = React.useState(true);
    const [csvData, setCsvData] = React.useState(null);
    const [isenabledUpload, setisenabledUpload] = React.useState(true);
    const [currentImage, setCurrentImage] = React.useState(0);
    const [isViewerOpen, setIsViewerOpen] = React.useState(false);
    const startTime = newData.startTime
    const fileInputRef = useRef();

    const values = [
        { title: "Pet Name", classificationKey: "cat_name" },
        { title: "Is a Cat", classificationKey: "notCat" },
        { title: "False Event", classificationKey: "falseTrigger" },
        { title: "Start Interaction", classificationKey: "startinteraction" },
        { title: "End Interaction", classificationKey: "endinteraction" },
        { title: "Labels", classificationKey: "tags" },
        { title: "Firmware Version", classificationKey: "firmware_version" },
        { title: "Consumption", classificationKey: "consumption" },
        { title: "Extra Information", classificationKey: "description" },
        { title: "Video File Name", classificationKey: "file_name" },
        { title: "Session Video", classificationKey: "cloud_video_file_url" },
        { title: "YOLO Modal Images", classificationKey: "cloud_images" },
        { title: "Label File", classificationKey: "label_file_name" }
    ];

    React.useEffect(() => {
        dataLoad();
        video_download();
        get_label_file(newData);
    }, [setlabelData]);

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    const openImage = () => {
        {
            imageURL.map((src, index) => (
                openImageViewer(index)
            ))
        }
    }

    const handleDownloadCSV = () => {
        window.location.href = csvData.url;
    };

    const dataLoad = () => {
        const url = `/get-cat-feeder-label?event_id=${eventId}&session_start_date=${startTime}`;
        axiosInstance
            .get(url)
            .then((response) => {
                const item = response.data.data;

                setlabelData(prevObject => ({ ...prevObject, firmware_version: firmware_version }));
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const video_download = (event) => {
        axiosInstance.get('/get-cat-feeder-video-download?event_id=' + eventId + '&video_url=' + cloud_video_file_url)
            .then((response, error) => {
                if (error) {
                    return;
                }
                setVideoURL(response.data.video_url)
                setImageURL(response.data.image_urls)

            }).catch((error) => {
                setIsLoading(false);
            });
    }

    const get_label_file = () => {
        axiosInstance.get('/get-cat-feeder-label-file-download?event_id=' + eventId + '&startTime=' + startTime)
            .then((response, error) => {
                setisLoadingCSV(false);
                if (error) {
                    setError(error)

                    return;
                }
                if (response.data.data === 'Label File Not Available') {
                    setCsvData(null)
                    setisenabledUpload(false);
                } else {
                    setCsvData(response.data.data)
                    setisenabledUpload(true);
                    setisLoadingCSV(false);
                }

            }).catch((error) => {
                setisLoadingCSV(false);
                setisenabledUpload(false);
                toast.error('[API Error]: Request Failed with Status Code 500', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            });
    }

    const handleRemoveLabelFile = () => {
        const eventID = newData["id"]
        const startTime = newData["startTime"]
        const source = "cfc1"
        axiosInstance.get('/get-dog-collar-remove-labels?event_id=' + eventID +
            '&startTime=' + startTime + '&source=' + source, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }).then((response, error) => {
            if (error) {
                setError(error)
                setisLoadingCSV(false);
                return;
            }
            setisLoadingCSV(false);
            toast("Label File Removed Successfully!", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            setisLoadingCSV(true)
            get_label_file();
        }).catch((error) => {
            setisLoadingCSV(false);
        });
    }

    const handleDownload = () => {
        window.location.href = videoURL;
    };

    const renderFn = (key) => {
        if (key == "cloud_video_file_url") {
            if (videoURL != "") {

                return (
                    <a onClick={handleDownload} download style={{ color: "#096dd9", cursor: "pointer" }}>
                        Download Video
                    </a>)
            }
            else {
                return <span>No Video available for Download</span>
            }
        }
        else if (key == "cloud_images") {
            if (imageURL.length > 0) {

                return (
                    <a onClick={openImage} style={{ color: "#096dd9", cursor: "pointer" }}>Click Here to View Images</a>

                )
            }
            else {
                return <span>No Images available</span>
            }
        }
        else if (key === "label_file_name") {
            if (csvData != null && isLoadingCSV == false) {

                return (
                    [<a onClick={handleDownloadCSV} download style={{ color: "#096dd9", cursor: "pointer" }}>
                        {csvData.label_file_name}
                    </a>, <Tooltip title="Delete Label File">
                        <DeleteIcon onClick={() => handleRemoveLabelFile()} variant="contained" />
                    </Tooltip>])
            }
            else {
                return <span>No data available</span>
            }
        }
        else if (key != "cloud_video_file_url" && labelData[key] == undefined || labelData[key] == null) {
            return ""
        }
        else {
            return `${labelData[key]}`;
        }
    };

    const [newEventModalIsOpen, setEventModalIsOpen] = React.useState(false);
    const handleUpload = (label_file) => {

        const event = newData

        let uploaded_label_file = label_file.target.files[0]
        let device_serial_nos = newData.device_serial_nos

        axiosInstance.post('/get-cat-feeder-upload-labels?session_id=' + eventId, {
            cloud_video_file_url: event.cloud_video_file_url, pet_id: event.pet_id,
            device_serial_nos: device_serial_nos, file_name: event.file_name,
            session_ids: eventId, pet_name: event.pet_name, source: event.source, startTime: event.startTime,
            video_end_time: event.video_end_time, video_start_time: event.video_start_time,
            label_file: uploaded_label_file, label_file_name: uploaded_label_file.name
        }, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }).then((response, error) => {
            if (error) {
                setError(error)
                setIsLoading(false);
                return;
            }
            setIsLoading(false);
            if (response.data.message == "File Columns Mismatch" || response.data.message == "Incorrect Label File Uploaded") {

                toast.error('Request Failed,' + response.data.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }
            else if (response.data.message == "File upload failed") {
                toast.error('Request Failed, File upload failed', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }
            else {
                toast('Label File Uploaded!', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });


                setisLoadingCSV(true)
                setisenabledUpload(true);
                get_label_file(event)
            }
        }).catch((error) => {
            setIsLoading(false);
            toast.error('[API ERROR]: Request Failed', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        });
    };


    return (

        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6" fontWeight="bold">
                    Classification and Labels
                </Typography>
                <Tooltip title={isenabledUpload ? 'Please Delete Existing Label File ' : 'Please Upload Label File'} style={{marginLeft: '1000px'}}>
                    <Button
                        variant="contained"
                        startIcon={<FileUploadIcon />}
                        onClick={() => fileInputRef.current.click()}
                        sx={{
                            backgroundColor: "#000",
                            color: "#fff",
                            borderRadius: "50px",
                            padding: "5px 15px",
                            textTransform: "none",
                            display: "flex",
                            alignItems: "center",
                            fontSize: "12px",
                            "&:hover": {
                                backgroundColor: "#333",
                            },
                            "& .MuiButton-startIcon": {
                                marginRight: "5px",
                            },
                        }}
                    >
                        Upload Labels
                    </Button>
                    <input onChange={handleUpload} multiple={false} ref={fileInputRef} disabled={isenabledUpload} type='file' hidden />
                </Tooltip>
                <Button
                    variant="contained"
                    startIcon={<EditIcon />}
                    onClick={() => setEventModalIsOpen(true)}
                    sx={{
                        backgroundColor: "#000",
                        color: "#fff",
                        borderRadius: "50px",
                        padding: "5px 15px",
                        textTransform: "none",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "12px",
                        "&:hover": {
                            backgroundColor: "#333",
                        },
                        "& .MuiButton-startIcon": {
                            marginRight: "5px",
                        },
                    }}
                >
                    Edit Labels
                </Button>
            </Box>

            {!isLoading ? (
                <TableContainer component={Paper}>
                    <Table aria-label="labels and classifications table">
                        <TableHead>
                            <TableRow>
                                <TableHeaderCell>Title</TableHeaderCell>
                                <TableHeaderCell>Value</TableHeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {values.map((v, i) => (
                                <TableRow key={i}>
                                    <TableCell>{v.title}</TableCell>
                                    <TableCell>{renderFn(v.classificationKey)}</TableCell>
                                    {/* <TableCell>{videoURL ? (
                    <a onClick={handleDownload} download style={{ color: "#096dd9", cursor: "pointer" }}>
                      Download Video
                    </a>) : (
                    <span>No Video available for Download</span>
                  )}</TableCell> */}
                                </TableRow>
                            ))}
                            {isViewerOpen && (
                                <ImageViewer
                                    src={imageURL}
                                    currentIndex={currentImage}
                                    disableScroll={false}
                                    closeOnClickOutside={true}
                                    onClose={closeImageViewer}
                                />
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>) : (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "100vh",
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            {newEventModalIsOpen && (
                <CatFeederEventLabelModal
                    isModalOpen={newEventModalIsOpen}
                    closeModal={() => setEventModalIsOpen(false)}
                    eventId={eventId}
                    startTime={startTime}
                    videoStartTime=""
                    machineId=""
                    submitCallback={() => dataLoad()}
                />
            )}
            <ToastContainer />
        </Box>
    );
};

export default CatFeederLabelsAndClassifications;
