import React, { useState } from "react";
import ModuleCard from "../ModuleCard/ModuleCard";
import catLitterBox from "./../../assets/images/catlitterbox.png";
import catFeeder from "./../../assets/images/catbowl.png";
import dogbowl from "./../../assets/images/dogbowl.png";
import dogcollar from "./../../assets/images/dogcollar.png";
import catbowl from "./../../assets/images/catbowl.png";
import axiosInstance from "../../api/axios/axiosInstance";
import permissions from "./../../utils/permissions.json";
import catdogrules from "./../../assets/images/mak1.png"; 

import "./Home.css";

const Home = () => {

  const [data, setNewData] = useState();
  const [error, setError] = useState('');

  React.useEffect(() => {
    axiosInstance.post('/roles', {}, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
      .then((response, error) => {
        if (error) {
          setError(error)
          return;
        }
        setNewData(response.data);
      });
  }, [])

  const notificationsLinkVisible = data?.roles?.includes("message_sender")
  let PTCLinkVisibleRoles = false
  let frankenDevicesUsers = false
  let authUserRole = []
  if (typeof (data) !== 'undefined') {
    const roles = data["data"]["roles"]
    authUserRole = roles?.filter((i) => (i !== "admin")) ?? []
    PTCLinkVisibleRoles = roles?.includes("ptc_users")
    frankenDevicesUsers = roles?.includes("franken_users")
  }
  localStorage.setItem("enable_read_write", PTCLinkVisibleRoles)
  localStorage.setItem("frankenDevicesUsers", frankenDevicesUsers)
  localStorage.setItem("authUserRole", authUserRole)

  authUserRole = localStorage.getItem("authUserRole")
  const userRole = permissions?.permissions[authUserRole] ?? []


  return (
    <div className="home-container">
      <div className="content">
        <div className="main-content">
          {authUserRole == "slm_users" && (
            <ModuleCard
              image={catLitterBox}
              title="Cat Litter Box"
              subMenu={["Households", "Monitors", "Cats", "Events", "Location"]}
            />
          )}
          {authUserRole === "ptc_users" && (
            [<ModuleCard
              image={catLitterBox}
              title="Cat Litter Box"
              subMenu={["Households", "Monitors", "Cats", "Events", "Location"]}
            />,
            <ModuleCard
              image={catFeeder}
              title="Cat Feeder"
              subMenu={["Monitors", "Events"]}
            />,
            <ModuleCard
              image={dogcollar}
              title="Dog Collar"
              subMenu={["Dogs", "Events"]}
            />,
            <ModuleCard
              image={dogbowl}
              title="Dog Bowl"
              subMenu={["Dogs", "Events"]}
            />,
            <ModuleCard
              image={dogcollar}
              title="Franken Dog Collar"
              subMenu={["Dogs", "Events"]}
            />,
            <ModuleCard
              image={catbowl}
              title="Franken Cat Bowl"
              subMenu={["Cats", "Events"]}
            />,
            <ModuleCard
                image={dogbowl}
                title="Franken Dog Bowl"
                subMenu={["Dogs", "Events"]}
              />,
              <ModuleCard
                image={catdogrules}
                title="Microbiome Analysis Kit"
                subMenu={["PetQCheck"]}
              />
            ]
          )}
          {authUserRole === "rules_management_users" && (
            [
              <ModuleCard
                image={catdogrules}
                title="Microbiome Analysis Kit"
                subMenu={["PetQCheck"]}
              />
            ]
          )}
          {authUserRole == "franken_users" && (
            [
              <ModuleCard
                image={dogcollar}
                title="Franken Dog Collar"
                subMenu={["Dogs", "Events"]}
              />,
              <ModuleCard
                image={catbowl}
                title="Franken Cat Bowl"
                subMenu={["Cats", "Events"]}
              />,
              <ModuleCard
                image={dogbowl}
                title="Franken Dog Bowl"
                subMenu={["Dogs", "Events"]}
              />]
          )}
          {authUserRole == "dog_collar_users" && (
            [
              <ModuleCard
                image={dogcollar}
                title="Dog Collar"
                subMenu={["Dogs", "Events"]}
              />]
          )}
          {authUserRole == "feeder_collar_users" && (
            [
              <ModuleCard
                image={catFeeder}
                title="Cat Feeder"
                subMenu={["Monitors", "Events"]}
              />,
              <ModuleCard
                image={dogcollar}
                title="Dog Collar"
                subMenu={["Dogs", "Events"]}
              />]
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
