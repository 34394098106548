import React, { useState, useEffect } from "react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    Radio,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Box
} from "@mui/material";
import { styled } from "@mui/system";
import { useForm, Controller } from "react-hook-form";
import axiosInstance from "../../api/axios/axiosInstance";
import rules from "../petqcheckPreData.json";
import { toast, ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const BulkDataLoadModal = ({
    isModalOpen,
    closeModal,
    dataTransformedForBulkUpload
}) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [isSaving, setIsSaving] = React.useState(false);

    const [rows, setRows] = useState(dataTransformedForBulkUpload);

    // Handle input change
    const handleChange = (index, field, value) => {
        const updatedRows = [...rows];
        updatedRows[index][field] = value;
        if (field == "sectionId") {
            updatedRows[index]["variable1_name"] = rules.rules[value]["variable1_name"].toString() ?? "";
            updatedRows[index]["variable1_value"] = rules.rules[value]["variable1_value"] ?? [];
            updatedRows[index]["variable2_name"] = rules.rules[value]["variable2_name"].toString() ?? "";
            updatedRows[index]["variable2_value"] = rules.rules[value]["variable2_value"] ?? [];
            updatedRows[index]["variable3_name"] = rules.rules[value]["variable3_name"].toString() ?? "";
            updatedRows[index]["variable3_value"] = rules.rules[value]["variable3_value"] ?? [];
            updatedRows[index]["variable4_name"] = rules.rules[value]["variable4_name"].toString() ?? "";
            updatedRows[index]["variable4_value"] = rules.rules[value]["variable4_value"] ?? [];
            updatedRows[index]["variable5_name"] = rules.rules[value]["variable5_name"].toString() ?? "";
            updatedRows[index]["variable5_value"] = rules.rules[value]["variable5_value"] ?? [];
            updatedRows[index]["variable6_name"] = rules.rules[value]["variable6_name"].toString() ?? "";
            updatedRows[index]["variable6_value"] = rules.rules[value]["variable6_value"] ?? [];
            updatedRows[index]["variable7_name"] = rules.rules[value]["variable7_name"].toString() ?? "";
            updatedRows[index]["variable7_value"] = rules.rules[value]["variable7_value"] ?? [];
            updatedRows[index]["variable8_name"] = rules.rules[value]["variable8_name"].toString() ?? "";
            updatedRows[index]["variable8_value"] = rules.rules[value]["variable8_value"] ?? [];
            updatedRows[index]["variable9_name"] = rules.rules[value]["variable9_name"].toString() ?? "";
            updatedRows[index]["variable9_value"] = rules.rules[value]["variable9_value"] ?? [];
            updatedRows[index]["variable10_name"] = rules.rules[value]["variable10_name"].toString() ?? "";
            updatedRows[index]["variable10_value"] = rules.rules[value]["variable10_value"] ?? [];
            updatedRows[index]["variable11_name"] = rules.rules[value]["variable11_name"].toString() ?? "";
            updatedRows[index]["variable11_value"] = rules.rules[value]["variable11_value"] ?? [];
            updatedRows[index]["variable12_name"] = rules.rules[value]["variable12_name"].toString() ?? "";
            updatedRows[index]["variable12_value"] = rules.rules[value]["variable12_value"] ?? [];
        }
        setRows(updatedRows);
    };


    // Add a new row
    const addRow = () => {
        setRows([...rows, { id: rows.length + 1, report_id: "", segment_id: "", sub_section_id: "", section_id: [] }]);
    };

    // Remove a row
    const removeRow = (index) => {
        setRows(rows.filter((_, i) => i !== index));
    };

    // Submit form
    const handleSubmit = (e) => {
        e.preventDefault();
        const data = rows.map(row => ({
            ...row,
            section_id: row["sectionId"].join(", "),
            variable_1_name: row["variable1_name"],
            variable_2_name: row["variable2_name"],
            variable_3_name: row["variable3_name"],
            variable_4_name: row["variable4_name"],
            variable_5_name: row["variable5_name"],
            variable_6_name: row["variable6_name"],
            variable_7_name: row["variable7_name"],
            variable_8_name: row["variable8_name"],
            variable_9_name: row["variable9_name"],
            variable_10_name: row["variable10_name"],
            variable_11_name: row["variable11_name"],
            variable_12_name: row["variable12_name"],
            variable_1_value: row["variable1_value"].join(", "),
            variable_2_value: row["variable2_value"].join(", "),
            variable_3_value: row["variable3_value"].join(", "),
            variable_4_value: row["variable4_value"].join(", "),
            variable_5_value: row["variable5_value"].join(", "),
            variable_6_value: row["variable6_value"].join(", "),
            variable_7_value: row["variable7_value"].join(", "),
            variable_8_value: row["variable8_value"].join(", "),
            variable_9_value: row["variable9_value"].join(", "),
            variable_10_value: row["variable10_value"].join(", "),
            variable_11_value: row["variable11_value"].join(", "),
            variable_12_value: row["variable12_value"].join(", "),
          }));
        // console.log("dayaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", data)
        // axiosInstance.post('/petqcheck-rules-bulk-update', rows).then((response, error) => {
        //     setIsLoading(false);
        //     if (response.data.message === 'PETQ Check Expert Rule Update Successful') {
        //         toast("PetQCheck Rule Updated Successfully!", {
        //             position: "bottom-right",
        //             autoClose: 5000,
        //             hideProgressBar: false,
        //             closeOnClick: true,
        //             pauseOnHover: true,
        //             draggable: true,
        //             progress: undefined,
        //             theme: "light",
        //             transition: Bounce,
        //           });
        //         closeModal()
        //         setIsLoading(false);
        //     }
        //     setIsLoading(false);
        // })
    };


    const StyledTableCell = styled(TableCell)({
        fontWeight: 'bold',
        backgroundColor: '#f5f5f5',
    });

    const FullWidthPaper = styled(Paper)({
        overflowX: 'auto',
    });

    const FullWidthTableContainer = styled(TableContainer)({
        // width: '2600px',
    });

    useEffect(() => {
    }, [])


    let columns = [

        {
            title: "Report ID",
            dataIndex: "report_id",
            key: "report_id",
            width: 300,
        },
        {
            title: "Segment ID",
            dataIndex: "segment_id",
        },
        {
            title: "Sub Section ID",
            dataIndex: "sub_section_id",
        },
        {
            title: "Insight ID",
            dataIndex: "insight_id",

        },
        {
            title: "Section ID",
            dataIndex: "section_id",

        },
        {
            title: "Input Attribute 1 Name",
            dataIndex: "variable1_name",

        },
        {
            title: "Input Attribute 1 Value",
            dataIndex: "variable1_value",

        },

        {
            title: "Input Attribute 2 Name",
            dataIndex: "variable2_name",

        },
        {
            title: "Input Attribute 2 Value",
            dataIndex: "variable2_value",

        },

        {
            title: "Input Attribute 3 Name",
            dataIndex: "variable3_name",

        },
        {
            title: "Input Attribute 3 Value",
            dataIndex: "variable3_value",

        },

        {
            title: "Input Attribute 4 Name",
            dataIndex: "variable4_name",

        },
        {
            title: "Input Attribute 4 Value",
            dataIndex: "variable4_value",

        },
        {
            title: "Input Attribute 5 Name",
            dataIndex: "variable5_name",

        },
        {
            title: "Input Attribute 5 Value",
            dataIndex: "variable5_value",

        },
        {
            title: "Input Attribute 6 Name",
            dataIndex: "variable6_name",

        },
        {
            title: "Input Attribute 6 Value",
            dataIndex: "variable6_value",

        },
        {
            title: "Input Attribute 7 Name",
            dataIndex: "variable7_name",

        },
        {
            title: "Input Attribute 7 Value",
            dataIndex: "variable7_value",

        },
        {
            title: "Input Attribute 8 Name",
            dataIndex: "variable8_name",

        },
        {
            title: "Input Attribute 8 Value",
            dataIndex: "variable8_value",

        },
        {
            title: "Input Attribute 9 Name",
            dataIndex: "variable9_name",

        },
        {
            title: "Input Attribute 9 Value",
            dataIndex: "variable9_value",

        },
        {
            title: "Input Attribute 10 Name",
            dataIndex: "variable10_name",

        },
        {
            title: "Input Attribute 10 Value",
            dataIndex: "variable10_value",

        },
        {
            title: "Input Attribute 11 Name",
            dataIndex: "variable11_name",

        },
        {
            title: "Input Attribute 11 Value",
            dataIndex: "variable11_value",

        },
        {
            title: "Input Attribute 12 Name",
            dataIndex: "variable12_name",

        },
        {
            title: "Input Attribute 12 Value",
            dataIndex: "variable12_value",

        }
    ]

    return (
        <Dialog open={isModalOpen} onClose={closeModal} fullWidth maxWidth="">
            <DialogTitle>
                {`Add New Rules`}
            </DialogTitle>
            <DialogContent>
                {isLoading && <CircularProgress />}
                {!isLoading && (
                    <form onSubmit={handleSubmit}>
                        <FullWidthPaper>
                            <FullWidthTableContainer>

                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <StyledTableCell key={column.key}>
                                                    {column.sorter ? (
                                                        <TableSortLabel
                                                            active={true}
                                                        >
                                                            {column.title}
                                                        </TableSortLabel>
                                                    ) : (
                                                        column.title
                                                    )}
                                                </StyledTableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {dataTransformedForBulkUpload.map((row, index) => (
                                            <TableRow key={row.id}>
                                                <TableCell key={"report_id"}>

                                                    <input
                                                        type="text"
                                                        name="report_id"
                                                        value={row.report_id}
                                                        onChange={(e) => handleChange(index, "report_id", e.target.value)}
                                                    />

                                                </TableCell>

                                                <TableCell key={"segment_id"}>

                                                    <input
                                                        type="text"
                                                        name="segment_id"
                                                        value={row.segment_id}
                                                        onChange={(e) => handleChange(index, "segment_id", e.target.value)}
                                                    />

                                                </TableCell>

                                                <TableCell key={"sub_section_id"}>

                                                    <input
                                                        type="text"
                                                        name="sub_section_id"
                                                        value={row.sub_section_id}
                                                        onChange={(e) => handleChange(index, "sub_section_id", e.target.value)}
                                                    />

                                                </TableCell>

                                                <TableCell key={"insight_id"}>

                                                    <input
                                                        type="text"
                                                        name="insight_id"
                                                        value={row.insight_id}
                                                        onChange={(e) => handleChange(index, "insight_id", e.target.value)}
                                                    />

                                                </TableCell>

                                                <TableCell key={"sectionId"}>

                                                    <Select
                                                        name="sectionId"
                                                        id="sectionId"
                                                        defaultValue={row.sectionId}
                                                        onChange={(e) => handleChange(index, "sectionId", e.target.value)}

                                                    >
                                                        {rules.rules.allSectionIds.map((data, i) => (
                                                            <MenuItem key={i} value={data}>
                                                                {data}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>

                                                </TableCell>

                                                <TableCell key={"variable1_name"}>

                                                    <input
                                                        type="text"
                                                        name="variable1_name"
                                                        value={row.variable1_name}
                                                    />

                                                </TableCell>

                                                <TableCell key={"variable1_value"}>

                                                    <Select
                                                        name="variable1_value"
                                                        defaultValue={row.variable1_value}
                                                        label={"Variable Value"}
                                                        onChange={(e) => handleChange(index, "variable1_value", e.target.value)}

                                                    >
                                                        {row?.variable1Allvalues?.map((data, i) => (
                                                            <MenuItem key={i} value={data}>
                                                                {data}
                                                            </MenuItem>
                                                        ))
                                                        }
                                                    </Select>

                                                </TableCell>








                                                <TableCell key={"variable2_name"}>

                                                    <input
                                                        type="text"
                                                        name="variable2_name"
                                                        value={row.variable2_name}
                                                    />

                                                </TableCell>

                                                <TableCell key={"variable2_value"}>

                                                    <Select
                                                        name="variable2_value"
                                                        defaultValue={row.variable2_value}
                                                        label={"Variable Value"}
                                                        onChange={(e) => handleChange(index, "variable2_value", e.target.value)}

                                                    >
                                                        {row?.variable2Allvalues?.map((data, i) => (
                                                            <MenuItem key={i} value={data}>
                                                                {data}
                                                            </MenuItem>
                                                        ))
                                                        }
                                                    </Select>

                                                </TableCell>
                                                <TableCell key={"variable3_name"}>

                                                    <input
                                                        type="text"
                                                        name="variable3_name"
                                                        value={row.variable3_name}
                                                    />

                                                </TableCell>

                                                <TableCell key={"variable3_value"}>

                                                    <Select
                                                        name="variable3_value"
                                                        defaultValue={row.variable3_value}
                                                        label={"Variable Value"}
                                                        onChange={(e) => handleChange(index, "variable3_value", e.target.value)}

                                                    >
                                                        {row?.variable3Allvalues?.map((data, i) => (
                                                            <MenuItem key={i} value={data}>
                                                                {data}
                                                            </MenuItem>
                                                        ))
                                                        }
                                                    </Select>

                                                </TableCell>
                                                <TableCell key={"variable4_name"}>

                                                    <input
                                                        type="text"
                                                        name="variable4_name"
                                                        value={row.variable4_name}
                                                    />

                                                </TableCell>

                                                <TableCell key={"variable4_value"}>

                                                    <Select
                                                        name="variable4_value"
                                                        defaultValue={row.variable4_value}
                                                        label={"Variable Value"}
                                                        onChange={(e) => handleChange(index, "variable4_value", e.target.value)}

                                                    >
                                                        {row?.variable4Allvalues?.map((data, i) => (
                                                            <MenuItem key={i} value={data}>
                                                                {data}
                                                            </MenuItem>
                                                        ))
                                                        }
                                                    </Select>

                                                </TableCell>
                                                <TableCell key={"variable5_name"}>

                                                    <input
                                                        type="text"
                                                        name="variable5_name"
                                                        value={row.variable5_name}
                                                    />

                                                </TableCell>

                                                <TableCell key={"variable5_value"}>

                                                    <Select
                                                        name="variable5_value"
                                                        defaultValue={row.variable5_value}
                                                        label={"Variable Value"}
                                                        onChange={(e) => handleChange(index, "variable5_value", e.target.value)}

                                                    >
                                                        {row?.variable5Allvalues?.map((data, i) => (
                                                            <MenuItem key={i} value={data}>
                                                                {data}
                                                            </MenuItem>
                                                        ))
                                                        }
                                                    </Select>

                                                </TableCell>
                                                <TableCell key={"variable6_name"}>

                                                    <input
                                                        type="text"
                                                        name="variable6_name"
                                                        value={row.variable6_name}
                                                    />

                                                </TableCell>

                                                <TableCell key={"variable6_value"}>

                                                    <Select
                                                        name="variable6_value"
                                                        defaultValue={row.variable6_value}
                                                        label={"Variable Value"}
                                                        onChange={(e) => handleChange(index, "variable6_value", e.target.value)}

                                                    >
                                                        {row?.variable6Allvalues?.map((data, i) => (
                                                            <MenuItem key={i} value={data}>
                                                                {data}
                                                            </MenuItem>
                                                        ))
                                                        }
                                                    </Select>

                                                </TableCell>
                                                <TableCell key={"variable7_name"}>

                                                    <input
                                                        type="text"
                                                        name="variable7_name"
                                                        value={row.variable7_name}
                                                    />

                                                </TableCell>

                                                <TableCell key={"variable7_value"}>

                                                    <Select
                                                        name="variable7_value"
                                                        defaultValue={row.variable7_value}
                                                        label={"Variable Value"}
                                                        onChange={(e) => handleChange(index, "variable7_value", e.target.value)}

                                                    >
                                                        {row?.variable7Allvalues?.map((data, i) => (
                                                            <MenuItem key={i} value={data}>
                                                                {data}
                                                            </MenuItem>
                                                        ))
                                                        }
                                                    </Select>

                                                </TableCell>
                                                <TableCell key={"variable8_name"}>

                                                    <input
                                                        type="text"
                                                        name="variable8_name"
                                                        value={row.variable8_name}
                                                    />

                                                </TableCell>

                                                <TableCell key={"variable8_value"}>

                                                    <Select
                                                        name="variable8_value"
                                                        defaultValue={row.variable8_value}
                                                        label={"Variable Value"}
                                                        onChange={(e) => handleChange(index, "variable8_value", e.target.value)}

                                                    >
                                                        {row?.variable8Allvalues?.map((data, i) => (
                                                            <MenuItem key={i} value={data}>
                                                                {data}
                                                            </MenuItem>
                                                        ))
                                                        }
                                                    </Select>

                                                </TableCell>
                                                <TableCell key={"variable9_name"}>

                                                    <input
                                                        type="text"
                                                        name="variable9_name"
                                                        value={row.variable9_name}
                                                    />

                                                </TableCell>

                                                <TableCell key={"variable9_value"}>

                                                    <Select
                                                        name="variable9_value"
                                                        defaultValue={row.variable9_value}
                                                        label={"Variable Value"}
                                                        onChange={(e) => handleChange(index, "variable9_value", e.target.value)}

                                                    >
                                                        {row?.variable9Allvalues?.map((data, i) => (
                                                            <MenuItem key={i} value={data}>
                                                                {data}
                                                            </MenuItem>
                                                        ))
                                                        }
                                                    </Select>

                                                </TableCell>
                                                <TableCell key={"variable10_name"}>

                                                    <input
                                                        type="text"
                                                        name="variable10_name"
                                                        value={row.variable10_name}
                                                    />

                                                </TableCell>

                                                <TableCell key={"variable10_value"}>

                                                    <Select
                                                        name="variable10_value"
                                                        defaultValue={row.variable10_value}
                                                        label={"Variable Value"}
                                                        onChange={(e) => handleChange(index, "variable10_value", e.target.value)}

                                                    >
                                                        {row?.variable10Allvalues?.map((data, i) => (
                                                            <MenuItem key={i} value={data}>
                                                                {data}
                                                            </MenuItem>
                                                        ))
                                                        }
                                                    </Select>

                                                </TableCell>
                                                <TableCell key={"variable11_name"}>

                                                    <input
                                                        type="text"
                                                        name="variable11_name"
                                                        value={row.variable11_name}
                                                    />

                                                </TableCell>

                                                <TableCell key={"variable11_value"}>

                                                    <Select
                                                        name="variable11_value"
                                                        defaultValue={row.variable11_value}
                                                        label={"Variable Value"}
                                                        onChange={(e) => handleChange(index, "variable11_value", e.target.value)}

                                                    >
                                                        {row?.variable11Allvalues?.map((data, i) => (
                                                            <MenuItem key={i} value={data}>
                                                                {data}
                                                            </MenuItem>
                                                        ))
                                                        }
                                                    </Select>

                                                </TableCell>
                                                <TableCell key={"variable12_name"}>

                                                    <input
                                                        type="text"
                                                        name="variable12_name"
                                                        value={row.variable12_name}
                                                    />

                                                </TableCell>

                                                <TableCell key={"variable12_value"}>

                                                    <Select
                                                        name="variable12_value"
                                                        defaultValue={row.variable12_value}
                                                        label={"Variable Value"}
                                                        onChange={(e) => handleChange(index, "variable12_value", e.target.value)}

                                                    >
                                                        {row?.variable12Allvalues?.map((data, i) => (
                                                            <MenuItem key={i} value={data}>
                                                                {data}
                                                            </MenuItem>
                                                        ))
                                                        }
                                                    </Select>

                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </FullWidthTableContainer>

                        </FullWidthPaper>



                        <DialogActions>
                            <Button onClick={closeModal} color="primary">
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                disabled={isSaving}
                            >
                                {"Update Rules"}
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default BulkDataLoadModal;