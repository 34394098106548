import React, { useState, useEffect } from "react";
import "./PetQCheckRules.css";
import { Card, CardContent, Grid, IconButton, Button } from "@mui/material";
import AddLocationIcon from '@mui/icons-material/AddLocation';
import DeviceSnSuffixSearch from "../../../../utils/DeviceSnSuffixSearch";
import PetQCheckRulesTable from "../../../Table/PetQCheckRulesTable/PetQCheckRulesTable";
import { useSearchParams } from "../../../../utils/useSearchParams";
import axiosInstance from "../../../../api/axios/axiosInstance";
import LoadingSpinner from "../../../LoadingSpinner/component";
import PetQCheckCreateModal from "../../../../utils/rulesUtils/PetQCheckCreateModal";
import BulkDataLoadModal from "../../../../utils/rulesUtils/bulkInsertUpdate";
import rules from "../../../../utils/petqcheckPreData.json";

const PetQCheckRules = () => {
    const [newData, setNewData] = React.useState();
    const [newDataRevision, setNewDataRevision] = React.useState();
    const [error, setError] = useState('');
    const [newRuleModalIsOpen, setNewRuleModalIsOpen] = useState();
    const [bulkRuleModalIsOpen, setBulkRuleModalIsOpen] = useState(false);
    const [selectedRules, setselectedRules] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams({
        page: 1,
        pageSize: 50,
        sort: "SN_ASC",
        snSuffix: ""
    })
    const [isLoading, setisLoading] = React.useState(true);

    const [isupdateTable, setisupdateTable] = React.useState(2)

    const updateBulkRules = (rules) => {
        setselectedRules(rules);
    }


    const updateTable = (newState) => {
        setisupdateTable(newState);
    };

    useEffect(() => {
        setisLoading(true);
        let url = "/petqcheck-rules?page=" + searchParams.get("page") +
            "&pageSize=" + searchParams.get("pageSize")
            + "&sectionId=" + searchParams.get("snSuffix")
        axiosInstance.get(url)
            .then((response, error) => {
                if (error) {
                    // useErrorHandler(error)
                    setError(error)
                    return;
                }
                setNewData(response.data)
                setisLoading(false);
            }).catch((error) => {
                setisLoading(false);
            })


    }, [searchParams, setNewData, isupdateTable])


    const handleSearch = (newSnSuffix) => {
        let newSearchParams = {
            ...Object.fromEntries(searchParams),
            page: 1,
            snSuffix: newSnSuffix.trim(),
        }
        if (newSnSuffix === "") {
            delete newSearchParams["snSuffix"]
        }
        setisLoading(true)
        setSearchParams(newSearchParams);
    };


    const pagination = {
        current: searchParams.get("page")
            ? Number(searchParams.get("page"))
            : 0,
        pageSize: searchParams.get("pageSize")
            ? Number(searchParams.get("pageSize"))
            : 0,
        onChange: (page, pageSize) => {
            setSearchParams({
                ...Object.fromEntries(searchParams),
                page,
                pageSize,
            })
        },
        total: parseInt(newData?.pageInfo.totalCount ?? 0),
    }

    const dataTransformedForTable = newData?.data.map((rule) => {
        return {
            ...rule,
        }
    }) ?? []


    const filteredRules = selectedRules.length > 0 ? newData?.data.filter(data => selectedRules.includes(data.id)) : []


    const dataTransformedForBulkUpload = filteredRules.map((rule) => {
        return {
            ...rule,
            "sectionId": [rule.section_id],
            "variable1_value": [rule.variable1_value],
            "variable1Allvalues": rules.rules[rule.section_id]["variable1_value"] ?? [],
            "variable2_value": [rule.variable2_value],
            "variable2Allvalues": rules.rules[rule.section_id]["variable2_value"] ?? [],
            "variable3_value": [rule.variable3_value],
            "variable3Allvalues": rules.rules[rule.section_id]["variable3_value"] ?? [],
            "variable4_value": [rule.variable4_value],
            "variable4Allvalues": rules.rules[rule.section_id]["variable4_value"] ?? [],
            "variable5_value": [rule.variable5_value],
            "variable5Allvalues": rules.rules[rule.section_id]["variable5_value"] ?? [],
            "variable6_value": [rule.variable6_value],
            "variable6Allvalues": rules.rules[rule.section_id]["variable6_value"] ?? [],
            "variable7_value": [rule.variable7_value],
            "variable7Allvalues": rules.rules[rule.section_id]["variable7_value"] ?? [],
            "variable8_value": [rule.variable8_value],
            "variable8Allvalues": rules.rules[rule.section_id]["variable8_value"] ?? [],
            "variable9_value": [rule.variable9_value],
            "variable9Allvalues": rules.rules[rule.section_id]["variable9_value"] ?? [],
            "variable10_value": [rule.variable10_value],
            "variable10Allvalues": rules.rules[rule.section_id]["variable10_value"] ?? [],
            "variable11_value": [rule.variable11_value],
            "variable11Allvalues": rules.rules[rule.section_id]["variable11_value"] ?? [],
            "variable12_value": [rule.variable12_value],
            "variable12Allvalues": rules.rules[rule.section_id]["variable12_value"] ?? [],
        }
    }) ?? []




    const updateParentState = (value) => {
        console.log("Parent state updated with value: ", value);
    };
    return !isLoading ? (
        <div className="module-content">
            <h5>PetQCheck Rules</h5>
            <Card>
                <CardContent>
                    <Grid container alignItems="center">
                        <Grid item xs>
                            <DeviceSnSuffixSearch onSearch={handleSearch} defaultValue={searchParams.get("snSuffix") ?? ""} />
                        </Grid>
                        <Grid item>

                            <Button
                                key="BulkLoad"
                                onClick={() => setBulkRuleModalIsOpen(true)}
                                icon={<AddLocationIcon />}
                            >
                                Bulk Load Rules
                            </Button>
                        </Grid>
                        <Grid item>

                            <Button
                                key="AddLocation"
                                onClick={() => setNewRuleModalIsOpen(true)}
                                icon={<AddLocationIcon />}
                            >
                                Add New Rule
                            </Button>
                        </Grid>
                    </Grid>


                    <br></br>

                    <Grid container>

                        <PetQCheckRulesTable
                            data={dataTransformedForTable}
                            loading={false}
                            pagination={pagination}
                            updateParentState={updateTable}
                            isupdateTable={isupdateTable}
                            onUpdateRules={updateBulkRules}
                        />
                    </Grid>
                    {newRuleModalIsOpen && (
                        <PetQCheckCreateModal
                            isModalOpen={true}
                            closeModal={() => {
                                setNewRuleModalIsOpen(false);
                                updateParentState(isupdateTable + 1)
                            }}
                        />
                    )}
                    {bulkRuleModalIsOpen && (
                        <BulkDataLoadModal
                            isModalOpen={true}
                            closeModal={() => {
                                setBulkRuleModalIsOpen(false);
                                updateParentState(isupdateTable + 1)
                            }}
                            dataTransformedForBulkUpload={dataTransformedForBulkUpload}
                        />
                    )}
                </CardContent>
            </Card>


        </div>
    ) : (
        <LoadingSpinner />
    );
};

export default PetQCheckRules;
