import React, { useState, useEffect } from "react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    Radio,
    TextField,
    Select,
    MenuItem,
    InputLabel
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import axiosInstance from "../../api/axios/axiosInstance";
import rules from "../petqcheckPreData.json";
import { toast, ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PetQCheckCreateModal = ({
    isModalOpen,
    closeModal,
    selectedRule,
}) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [isSaving, setIsSaving] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(false);
    const [variableData, setVariableData] = React.useState({
        "sectionId": selectedRule !== undefined ? [selectedRule["section_id"]] : [],
        "variable1_name": selectedRule !== undefined ? selectedRule["variable1_name"] : "",
        "variable1_value": selectedRule !== undefined ? [selectedRule["variable1_value"]] : [],
        "variable2_name": selectedRule !== undefined ? selectedRule["variable2_name"] : "",
        "variable2_value": selectedRule !== undefined ? [selectedRule["variable2_value"]] : [],
        "variable3_name": selectedRule !== undefined ? selectedRule["variable3_name"] : "",
        "variable3_value": selectedRule !== undefined ? [selectedRule["variable3_value"]] : [],
        "variable4_name": selectedRule !== undefined ? selectedRule["variable4_name"] : "",
        "variable4_value": selectedRule !== undefined ? [selectedRule["variable4_value"]] : [],
        "variable5_name": selectedRule !== undefined ? selectedRule["variable5_name"] : "",
        "variable5_value": selectedRule !== undefined ? [selectedRule["variable5_value"]] : [],
        "variable6_name": selectedRule !== undefined ? selectedRule["variable6_name"] : "",
        "variable6_value": selectedRule !== undefined ? [selectedRule["variable6_value"]] : [],
        "variable7_name": selectedRule !== undefined ? selectedRule["variable7_name"] : "",
        "variable7_value": selectedRule !== undefined ? [selectedRule["variable7_value"]] : [],
        "variable8_name": selectedRule !== undefined ? selectedRule["variable8_name"] : "",
        "variable8_value": selectedRule !== undefined ? [selectedRule["variable8_value"]] : [],
        "variable9_name": selectedRule !== undefined ? selectedRule["variable9_name"] : "",
        "variable9_value": selectedRule !== undefined ? [selectedRule["variable9_value"]] : [],
        "variable10_name": selectedRule !== undefined ? selectedRule["variable10_name"] : "",
        "variable10_value": selectedRule !== undefined ? [selectedRule["variable10_value"]] : [],
        "variable11_name": selectedRule !== undefined ? selectedRule["variable11_name"] : "",
        "variable11_value": selectedRule !== undefined ? [selectedRule["variable11_value"]] : [],
        "variable12_name": selectedRule !== undefined ? selectedRule["variable12_name"] : "",
        "variable12_value": selectedRule !== undefined ? [selectedRule["variable12_value"]] : [],
    });

    const [form, setForm] = useState({
        report_id: "",
        segment_id: "",
        subsection_id: "",
        insight_id: "",
        variable_value: "",
        sectionId: [],
        variable1_name: "",
        variable1_value: "",
        variable2_name: "",
        variable2_value: "",
        variable3_name: "",
        variable3_value: "",
        variable4_name: "",
        variable4_value: "",
        variable5_name: "",
        variable5_value: "",
        variable6_name: "",
        variable6_value: "",
        variable7_name: "",
        variable7_value: "",
        variable8_name: "",
        variable8_value: "",
        variable9_name: "",
        variable9_value: "",
        variable10_name: "",
        variable10_value: "",
        variable11_name: "",
        variable11_value: "",
        variable12_name: "",
        variable12_value: ""
    });

    const handleFormChange = (field) => (event) => {
        if (field == "sectionId") {
            setVariableData({
                "variable1_name": rules.rules[event.target.value]["variable1_name"].toString() ?? "",
                "variable1_value": rules.rules[event.target.value]["variable1_value"] ?? [],
                "variable2_name": rules.rules[event.target.value]["variable2_name"].toString() ?? "",
                "variable2_value": rules.rules[event.target.value]["variable2_value"] ?? [],
                "variable3_name": rules.rules[event.target.value]["variable3_name"].toString() ?? "",
                "variable3_value": rules.rules[event.target.value]["variable3_value"] ?? [],
                "variable4_name": rules.rules[event.target.value]["variable4_name"].toString() ?? "",
                "variable4_value": rules.rules[event.target.value]["variable4_value"] ?? [],
                "variable5_name": rules.rules[event.target.value]["variable5_name"].toString() ?? "",
                "variable5_value": rules.rules[event.target.value]["variable5_value"] ?? [],
                "variable6_name": rules.rules[event.target.value]["variable6_name"].toString() ?? "",
                "variable6_value": rules.rules[event.target.value]["variable6_value"] ?? [],
                "variable7_name": rules.rules[event.target.value]["variable7_name"].toString() ?? "",
                "variable7_value": rules.rules[event.target.value]["variable7_value"] ?? [],
                "variable8_name": rules.rules[event.target.value]["variable8_name"].toString() ?? "",
                "variable8_value": rules.rules[event.target.value]["variable8_value"] ?? [],
                "variable9_name": rules.rules[event.target.value]["variable9_name"].toString() ?? "",
                "variable9_value": rules.rules[event.target.value]["variable9_value"] ?? [],
                "variable10_name": rules.rules[event.target.value]["variable10_name"].toString() ?? "",
                "variable10_value": rules.rules[event.target.value]["variable10_value"] ?? [],
                "variable11_name": rules.rules[event.target.value]["variable11_name"].toString() ?? "",
                "variable11_value": rules.rules[event.target.value]["variable11_value"] ?? [],
                "variable12_name": rules.rules[event.target.value]["variable12_name"].toString() ?? "",
                "variable12_value": rules.rules[event.target.value]["variable12_value"] ?? []
            })
        }

        setForm((prevForm) => ({
            ...prevForm,
            [field]: event.target.value
        }))

    };


    const { control, handleSubmit, setValue, reset, formState: { errors } } = useForm({
        defaultValues: {
            report_id: selectedRule !== undefined ? selectedRule["report_id"] : "",
            segment_id: selectedRule !== undefined ? selectedRule["segment_id"] : "",
            subsection_id: selectedRule !== undefined ? selectedRule["sub_section_id"] : "",
            insight_id: selectedRule !== undefined ? selectedRule["insight_id"] : "",
            variable_value: selectedRule !== undefined ? selectedRule["variable_value"] : "",
        }
    });

    const updatePetQCheckRules = (data) => {
        axiosInstance.put('/petqcheck-rules', data).then((response, error) => {
            setIsLoading(false);
            if (response.data.message === 'PETQ Check Expert Rule Update Successful') {
                toast("PetQCheck Rule Updated Successfully!", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                  });
                closeModal()
                setIsLoading(false);
            }
            setIsLoading(false);
        })
    }

    const createPetQCheckRules = (data) => {
        axiosInstance.post('/petqcheck-rules', data).then((response, error) => {
            setIsLoading(false);
            console.log("response.data.message", response.data.message)
            if (response.data.message === 'PETQCheck Creation Successful') {
                toast("PetQCheck Rule Created Successfully!", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                  });
                closeModal()
                setIsLoading(false);
            }
            setIsLoading(false);
        })
    }


    const onSubmit = async (values) => {
        setIsLoading(true);
        setIsSaving(true);
        try {
            if (selectedRule === undefined) {
                let request_data = {
                    "segment_id": values["segment_id"],
                    "report_id": values["report_id"],
                    "section_id": form["sectionId"],
                    "sub_section_id": values["subsection_id"],
                    "insight_id": Number(values["insight_id"]),
                    "variable_value": values["variable_value"],
                    "variable_1_name": variableData["variable1_name"],
                    "variable_1_value": form["variable1_value"].toString() ?? "",
                    "variable_2_name": variableData["variable2_name"],
                    "variable_2_value": form["variable2_value"].toString() ?? "",
                    "variable_3_name": variableData["variable3_name"],
                    "variable_3_value": form["variable3_value"].toString() ?? "",
                    "variable_4_name": variableData["variable4_name"],
                    "variable_4_value": form["variable4_value"].toString() ?? "",
                    "variable_5_name": variableData["variable5_name"],
                    "variable_5_value": form["variable5_value"].toString() ?? "",
                    "variable_6_name": variableData["variable6_name"],
                    "variable_6_value": form["variable6_value"].toString() ?? "",
                    "variable_7_name": variableData["variable7_name"],
                    "variable_7_value": form["variable7_value"].toString() ?? "",
                    "variable_8_name": variableData["variable8_name"],
                    "variable_8_value": form["variable8_value"].toString() ?? "",
                    "variable_9_name": variableData["variable9_name"],
                    "variable_9_value": form["variable9_value"].toString() ?? "",
                    "variable_10_name": variableData["variable10_name"],
                    "variable_10_value": form["variable10_value"].toString() ?? "",
                    "variable_11_name": variableData["variable11_name"],
                    "variable_11_value": form["variable11_value"].toString() ?? "",
                    "variable_12_name": variableData["variable12_name"],
                    "variable_12_value": form["variable12_value"].toString() ?? "",
                    "variable_value": values["variable_value"]
                }
                console.log("insert request_data", request_data)
                createPetQCheckRules(request_data)
                
            }
            else {
                let sectionId = form["sectionId"] !== "" ? form["sectionId"] : variableData["sectionId"].toString()
                let variable1_value = form["variable1_value"] !== "" ? form["variable1_value"] : variableData["variable1_value"].toString()
                let variable2_value = form["variable2_value"] !== "" ? form["variable2_value"] : variableData["variable2_value"].toString()
                let variable3_value = form["variable3_value"] !== "" ? form["variable3_value"] : variableData["variable3_value"].toString()
                let variable4_value = form["variable4_value"] !== "" ? form["variable4_value"] : variableData["variable4_value"].toString()
                let variable5_value = form["variable5_value"] !== "" ? form["variable5_value"] : variableData["variable5_value"].toString()
                let variable6_value = form["variable6_value"] !== "" ? form["variable6_value"] : variableData["variable6_value"].toString()
                let variable7_value = form["variable7_value"] !== "" ? form["variable7_value"] : variableData["variable7_value"].toString()
                let variable8_value = form["variable8_value"] !== "" ? form["variable8_value"] : variableData["variable8_value"].toString()
                let variable9_value = form["variable9_value"] !== "" ? form["variable9_value"] : variableData["variable9_value"].toString()
                let variable10_value = form["variable10_value"] !== "" ? form["variable10_value"] : variableData["variable10_value"].toString()
                let variable11_value = form["variable11_value"] !== "" ? form["variable11_value"] : variableData["variable11_value"].toString()
                let variable12_value = form["variable12_value"] !== "" ? form["variable12_value"] : variableData["variable12_value"].toString()

                let request_data = {
                    "combination_id": selectedRule["combination_id"],
                    "segment_id": values["segment_id"],
                    "report_id": values["report_id"],
                    "section_id": sectionId,
                    "sub_section_id": values["subsection_id"],
                    "insight_id": values["insight_id"],
                    "variable_value": values["variable_value"],
                    "variable_1_name": variableData["variable1_name"],
                    "variable_1_value": variable1_value,
                    "variable_2_name": variableData["variable2_name"],
                    "variable_2_value": variable2_value,
                    "variable_3_name": variableData["variable3_name"],
                    "variable_3_value": variable3_value,
                    "variable_4_name": variableData["variable4_name"],
                    "variable_4_value": variable4_value,
                    "variable_5_name": variableData["variable5_name"],
                    "variable_5_value": variable5_value,
                    "variable_6_name": variableData["variable6_name"],
                    "variable_6_value": variable6_value,
                    "variable_7_name": variableData["variable7_name"],
                    "variable_7_value": variable7_value,
                    "variable_8_name": variableData["variable8_name"],
                    "variable_8_value": variable8_value,
                    "variable_9_name": variableData["variable9_name"],
                    "variable_9_value": variable9_value,
                    "variable_10_name": variableData["variable10_name"],
                    "variable_10_value": variable10_value,
                    "variable_11_name": variableData["variable11_name"],
                    "variable_11_value": variable11_value,
                    "variable_12_name": variableData["variable12_name"],
                    "variable_12_value": variable12_value,
                    "variable_value": values["variable_value"]
                }
                console.log("update request_data", request_data)
                updatePetQCheckRules(request_data)
            }

            
        } catch (e) {
            // alert(e.message);
            setIsLoading(false);
        } finally {
            setIsSaving(false);
        }
    };
    return (
        <Dialog open={isModalOpen} onClose={closeModal}>
            <DialogTitle>
                {selectedRule
                    ? `Edit Rule`
                    : `Add New Rule`}
            </DialogTitle>
            <DialogContent>
                {isLoading && <CircularProgress />}
                {!isLoading && (
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <Controller
                            name="report_id"

                            control={control}
                            rules={{ required: true }}
                            render={({ field, fieldState }) => (
                                <TextField
                                    {...field}
                                    label="Report ID"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    error={!!errors.device_id}
                                    helperText={errors.device_id && `${errors.device_id.message}`}
                                />
                            )}
                        />

                        <Controller
                            name="segment_id"

                            control={control}
                            rules={{ required: true }}
                            render={({ field, fieldState }) => (
                                <TextField
                                    {...field}
                                    label="Segment ID"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    error={!!errors.device_id}
                                    helperText={errors.device_id && `${errors.device_id.message}`}
                                />
                            )}
                        />

                        <Controller
                            name="subsection_id"

                            control={control}
                            rules={{ required: true }}
                            render={({ field, fieldState }) => (
                                <TextField
                                    {...field}
                                    label="SubSection ID"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    error={!!errors.device_id}
                                    helperText={errors.device_id && `${errors.device_id.message}`}
                                />
                            )}
                        />
                        <Controller
                            name="insight_id"

                            control={control}
                            rules={{ required: true }}
                            render={({ field, fieldState }) => (
                                <TextField
                                    {...field}
                                    label="Insight ID"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    type="number"
                                    error={!!errors.device_id}
                                    helperText={errors.device_id && `${errors.device_id.message}`}
                                />
                            )}
                        />

                        <Controller
                            name="variable_value"

                            control={control}
                            rules={{ required: true }}
                            render={({ field, fieldState }) => (
                                <TextField
                                    {...field}
                                    label="Variable Value"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    error={!!errors.device_id}
                                    helperText={errors.device_id && `${errors.device_id.message}`}
                                />
                            )}
                        />




                        <InputLabel id="type-label">Section ID*</InputLabel>
                        <Controller
                            name="sectionId"
                            control={control}
                            rules={{ required: false }}
                            render={({ field: { onChange, value }, fieldState }) => (
                                <Select
                                    id="sectionId"
                                    style={{ width: "100%" }}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    placeholder={"Select Items"}
                                    defaultValue={variableData.sectionId}
                                    labelId="sectionId-label"
                                    onChange={handleFormChange("sectionId")}
                                    required={"Select SectionID"}
                                >
                                    {rules.rules.allSectionIds.map((data, i) => (
                                        <MenuItem key={i} value={data}>
                                            {data}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )} />




                        <InputLabel id="type-label">Input Attribute 1 Name</InputLabel>
                        <Controller
                            name="variable1_name"

                            control={control}
                            rules={{ required: false }}
                            render={({ field, fieldState }) => (
                                <TextField
                                    {...field}
                                    label="Input Attribute 1 Name"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    onChange={handleFormChange("variable1_name")}
                                    value={variableData.variable1_name}
                                />
                            )}
                        />
                        <InputLabel id="type-label">Input Attribute 1 Value</InputLabel>
                        <Controller
                            name="variable1_value"
                            control={control}
                            rules={{ required: false }}
                            render={({ field: { onChange, value }, fieldState }) => (
                                <Select
                                    id="variable1_value"
                                    style={{ width: "100%" }}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    placeholder={"Select Items"}
                                    defaultValue={variableData.variable1_value}
                                    labelId="variable1_value-label"
                                    onChange={handleFormChange("variable1_value")}
                                    required={"Please Select"}
                                >{variableData.variable1_value?.map((data, i) => (
                                    <MenuItem key={i} value={data}>
                                        {data}
                                    </MenuItem>
                                ))
                                    }
                                </Select>
                            )} />
                        <InputLabel id="type-label">Input Attribute 2 Name</InputLabel>
                        <Controller
                            name="variable2_name"

                            control={control}
                            rules={{ required: false }}
                            render={({ field, fieldState }) => (
                                <TextField
                                    {...field}
                                    label="Input Attribute 2 Name"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    value={variableData.variable2_name}
                                />
                            )}
                        />
                        <InputLabel id="type-label">Input Attribute 2 Value</InputLabel>
                        <Controller
                            name="variable2_value"
                            control={control}
                            rules={{ required: false }}
                            render={({ field: { onChange, value }, fieldState }) => (
                                <Select
                                    id="variable2_value"
                                    style={{ width: "100%" }}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    placeholder={"Select Items"}
                                    defaultValue={variableData.variable2_value}
                                    onChange={handleFormChange("variable2_value")}
                                    labelId="variable2_value-label"
                                >
                                    {variableData.variable2_value?.map((data, i) => (
                                        <MenuItem key={i} value={data}>
                                            {data}
                                        </MenuItem>
                                    ))
                                    }
                                </Select>
                            )} />
                        <InputLabel id="type-label">Input Attribute 3 Name</InputLabel>
                        <Controller
                            name="variable3_name"

                            control={control}
                            rules={{ required: false }}
                            render={({ field, fieldState }) => (
                                <TextField
                                    {...field}
                                    label="Input Attribute 3 Name"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    value={variableData.variable3_name}
                                />
                            )}
                        />
                        <InputLabel id="type-label">Input Attribute 3 Value</InputLabel>
                        <Controller
                            name="variable3_value"
                            control={control}
                            rules={{ required: false }}
                            render={({ field: { onChange, value }, fieldState }) => (
                                <Select
                                    id="cats"
                                    style={{ width: "100%" }}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    placeholder={"Select Items"}
                                    defaultValue={variableData.variable3_value}
                                    onChange={handleFormChange("variable3_value")}
                                    labelId="variable3_value-label"
                                >
                                    {variableData.variable3_value.map((data, i) => (
                                        <MenuItem key={i} value={data}>
                                            {data}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )} />
                        <InputLabel id="type-label">Input Attribute 4 Name</InputLabel>
                        <Controller
                            name="variable4_name"

                            control={control}
                            rules={{ required: false }}
                            render={({ field, fieldState }) => (
                                <TextField
                                    {...field}
                                    label="Input Attribute 4 Name"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    value={variableData.variable4_name}
                                />
                            )}
                        />
                        <InputLabel id="type-label">Input Attribute 4 Value</InputLabel>
                        <Controller
                            name="variable4_value"
                            control={control}
                            rules={{ required: false }}
                            render={({ field: { onChange, value }, fieldState }) => (
                                <Select
                                    id="variable4_value"
                                    style={{ width: "100%" }}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    defaultValue={variableData.variable4_value}
                                    placeholder={"Select Items"}
                                    onChange={handleFormChange("variable4_value")}
                                    labelId="variable4_value-label"
                                >
                                    {variableData.variable4_value.map((data, i) => (
                                        <MenuItem key={i} value={data}>
                                            {data}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )} />
                        <InputLabel id="type-label">Input Attribute 5 Name</InputLabel>
                        <Controller
                            name="variable5_name"

                            control={control}
                            rules={{ required: false }}
                            render={({ field, fieldState }) => (
                                <TextField
                                    {...field}
                                    label="Input Attribute 5 Name"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    value={variableData.variable5_name}
                                />
                            )}
                        />
                        <InputLabel id="type-label">Input Attribute 5 Value</InputLabel>
                        <Controller
                            name="variable5_value"
                            control={control}
                            rules={{ required: false }}
                            render={({ field: { onChange, value }, fieldState }) => (
                                <Select
                                    id="variable5_value"
                                    style={{ width: "100%" }}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    defaultValue={variableData.variable5_value}
                                    placeholder={"Select Items"}
                                    onChange={handleFormChange("variable5_value")}
                                    labelId="variable5_value-label"
                                >
                                    {variableData.variable5_value.map((data, i) => (
                                        <MenuItem key={i} value={data}>
                                            {data}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )} />
                        <InputLabel id="type-label">Input Attribute 6 Name</InputLabel>
                        <Controller
                            name="variable6_name"

                            control={control}
                            rules={{ required: false }}
                            render={({ field, fieldState }) => (
                                <TextField
                                    {...field}
                                    label="Input Attribute 6 Name"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    value={variableData.variable6_name}
                                />
                            )}
                        />
                        <InputLabel id="type-label">Input Attribute 6 Value</InputLabel>
                        <Controller
                            name="variable6_value"
                            control={control}
                            rules={{ required: false }}
                            render={({ field: { onChange, value }, fieldState }) => (
                                <Select
                                    id="variable6_value"
                                    style={{ width: "100%" }}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    placeholder={"Select Items"}
                                    defaultValue={variableData.variable6_value}
                                    onChange={handleFormChange("variable6_value")}
                                    labelId="variable6_value-label"
                                >
                                    {variableData.variable6_value.map((data, i) => (
                                        <MenuItem key={i} value={data}>
                                            {data}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )} />
                        <InputLabel id="type-label">Input Attribute 7 Name</InputLabel>
                        <Controller
                            name="variable7_name"

                            control={control}
                            rules={{ required: false }}
                            render={({ field, fieldState }) => (
                                <TextField
                                    {...field}
                                    label="Input Attribute 7 Name"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    value={variableData.variable7_name}
                                />
                            )}
                        />
                        <InputLabel id="type-label">Input Attribute 7 Value</InputLabel>
                        <Controller
                            name="variable7_value"
                            control={control}
                            rules={{ required: false }}
                            render={({ field: { onChange, value }, fieldState }) => (
                                <Select
                                    id="variable7_value"
                                    style={{ width: "100%" }}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    placeholder={"Select Items"}
                                    defaultValue={variableData.variable7_value}
                                    onChange={handleFormChange("variable7_value")}
                                    labelId="variable7_value-label"
                                >
                                    {variableData.variable7_value.map((data, i) => (
                                        <MenuItem key={i} value={data}>
                                            {data}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )} />
                        <InputLabel id="type-label">Input Attribute 8 Name</InputLabel>
                        <Controller
                            name="variable8_name"

                            control={control}
                            rules={{ required: false }}
                            render={({ field, fieldState }) => (
                                <TextField
                                    {...field}
                                    label="Input Attribute 8 Name"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    value={variableData.variable8_name}
                                />
                            )}
                        />
                        <InputLabel id="type-label">Input Attribute 8 Value</InputLabel>
                        <Controller
                            name="variable8_value"
                            control={control}
                            rules={{ required: false }}
                            render={({ field: { onChange, value }, fieldState }) => (
                                <Select
                                    id="variable8_value"
                                    style={{ width: "100%" }}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    placeholder={"Select Items"}
                                    defaultValue={variableData.variable8_value}
                                    onChange={handleFormChange("variable8_value")}
                                    labelId="variable8_value-label"
                                >
                                    {variableData.variable8_value.map((data, i) => (
                                        <MenuItem key={i} value={data}>
                                            {data}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )} />
                        <InputLabel id="type-label">Input Attribute 9 Name</InputLabel>
                        <Controller
                            name="variable9_name"

                            control={control}
                            rules={{ required: false }}
                            render={({ field, fieldState }) => (
                                <TextField
                                    {...field}
                                    label="Input Attribute 9 Name"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    value={variableData.variable9_name}
                                />
                            )}
                        />
                        <InputLabel id="type-label">Input Attribute 9 Value</InputLabel>
                        <Controller
                            name="variable9_value"
                            control={control}
                            rules={{ required: false }}
                            render={({ field: { onChange, value }, fieldState }) => (
                                <Select
                                    id="variable9_value"
                                    style={{ width: "100%" }}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    placeholder={"Select Items"}
                                    defaultValue={variableData.variable9_value}
                                    onChange={handleFormChange("variable9_value")}
                                    labelId="variable9_value-label"
                                >
                                    {variableData.variable9_value.map((data, i) => (
                                        <MenuItem key={i} value={data}>
                                            {data}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )} />
                        <InputLabel id="type-label">Input Attribute 10 Name</InputLabel>
                        <Controller
                            name="variable10_name"

                            control={control}
                            rules={{ required: false }}
                            render={({ field, fieldState }) => (
                                <TextField
                                    {...field}
                                    label="Input Attribute 10 Name"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    value={variableData.variable10_name}
                                />
                            )}
                        />
                        <InputLabel id="type-label">Input Attribute 10 Value</InputLabel>
                        <Controller
                            name="variable10_value"
                            control={control}
                            rules={{ required: false }}
                            render={({ field: { onChange, value }, fieldState }) => (
                                <Select
                                    id="variable10_value"
                                    style={{ width: "100%" }}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    placeholder={"Select Items"}
                                    defaultValue={variableData.variable10_value}
                                    onChange={handleFormChange("variable10_value")}
                                    labelId="variable10_value-label"
                                >
                                    {variableData.variable10_value.map((data, i) => (
                                        <MenuItem key={i} value={data}>
                                            {data}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )} />
                        <InputLabel id="type-label">Input Attribute 11 Name</InputLabel>
                        <Controller
                            name="variable11_name"

                            control={control}
                            rules={{ required: false }}
                            render={({ field, fieldState }) => (
                                <TextField
                                    {...field}
                                    label="Input Attribute 11 Name"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    value={variableData.variable11_name}
                                />
                            )}
                        />
                        <InputLabel id="type-label">Input Attribute 11 Value</InputLabel>
                        <Controller
                            name="variable11_value"
                            control={control}
                            rules={{ required: false }}
                            render={({ field: { onChange, value }, fieldState }) => (
                                <Select
                                    id="variable11_value"
                                    style={{ width: "100%" }}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    placeholder={"Select Items"}
                                    defaultValue={variableData.variable11_value}
                                    onChange={handleFormChange("variable11_value")}
                                    labelId="variable11_value-label"
                                >
                                    {variableData.variable11_value.map((data, i) => (
                                        <MenuItem key={i} value={data}>
                                            {data}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )} />
                        <InputLabel id="type-label">Input Attribute 12 Name</InputLabel>
                        <Controller
                            name="variable12_name"

                            control={control}
                            rules={{ required: false }}
                            render={({ field, fieldState }) => (
                                <TextField
                                    {...field}
                                    label="Input Attribute 12 Name"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    value={variableData.variable12_name}
                                />
                            )}
                        />
                        <InputLabel id="type-label">Input Attribute 12 Value</InputLabel>
                        <Controller
                            name="variable12_value"
                            control={control}
                            rules={{ required: false }}
                            render={({ field: { onChange, value }, fieldState }) => (
                                <Select
                                    id="variable12_value"
                                    style={{ width: "100%" }}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    placeholder={"Select Items"}
                                    defaultValue={variableData.variable12_value}
                                    onChange={handleFormChange("variable12_value")}
                                    labelId="variable12_value-label"
                                >
                                    {variableData.variable12_value.map((data, i) => (
                                        <MenuItem key={i} value={data}>
                                            {data}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )} />

                        <DialogActions>
                            <Button onClick={closeModal} color="primary">
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                disabled={isSaving}
                            // data-testid={tids.addCat.saveButton}
                            >
                                {selectedRule ? "Save Rule" : "Add Rule"}
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </DialogContent>
            <ToastContainer />
        </Dialog>
    );
};

export default PetQCheckCreateModal;
