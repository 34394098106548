import React, { useState, useEffect } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
    IconButton,
    Paper,
    Tooltip,
    Box,
    Select,
    MenuItem,
    Checkbox
} from "@mui/material";
import { styled } from "@mui/system";
import { Link, useSearchParams } from "react-router-dom";
import Highlighter from "react-highlight-words";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import moment from 'moment-timezone';
import { findChunksAtEndOfString } from "../../../utils/findChunksAtEndOfString";
// import EditCatActiveStateButton from "../../../utils/catUtills/EditCatActiveStateButton";
import EditPetQCheckButton from "../../../utils/rulesUtils/EditPetQCheckButton";
import axiosInstance from "../../../api/axios/axiosInstance";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarHalfIcon from '@mui/icons-material/StarHalf';

const NoData = styled('div')({
    opacity: 0.4,
    fontStyle: "italic",
});

const Ul = styled('ul')({
    margin: 0,
    paddingLeft: "1rem",
});

const ActionIconButton = styled(IconButton)({
    padding: 0,
});

const StyledTableCell = styled(TableCell)({
    fontWeight: 'bold',
    backgroundColor: '#f5f5f5',
});

const FullWidthPaper = styled(Paper)({
    width: '100%',
    overflowX: 'auto',
});

const FullWidthTableContainer = styled(TableContainer)({
    width: '100%',
});

const CustomPagination = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '10px',
    borderTop: '1px solid #e0e0e0',
    backgroundColor: '#fafafa',
});

const CustomTablePagination = (props) => {
    const {
        count,
        page,
        rowsPerPage,
        onPageChange,
        onRowsPerPageChange,
        rowsPerPageOptions,
        labelDisplayedRows,
        labelRowsPerPage,
        pagination
    } = props;

    return (
        <CustomPagination>
            <Box display="flex" alignItems="center">
                <Box>{labelRowsPerPage}</Box>
                <Select
                    value={rowsPerPage}
                    onChange={onRowsPerPageChange}
                    variant="outlined"
                    size="small"
                    style={{ marginLeft: 8, marginRight: 16 }}
                >
                    {rowsPerPageOptions.map((rowsPerPageOption) => (
                        <MenuItem key={rowsPerPageOption} value={rowsPerPageOption}>
                            {rowsPerPageOption}
                        </MenuItem>
                    ))}
                </Select>
                <Box>{labelDisplayedRows({ from: page * rowsPerPage + 1, to: Math.min(count, (page + 1) * rowsPerPage), count })}</Box>
            </Box>
            <Box display="flex" alignItems="center" marginLeft={2}>
                <IconButton
                    onClick={(event) => onPageChange(event, page - 1)}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    <ArrowBackIosIcon />
                </IconButton>
                <IconButton
                    onClick={(event) => onPageChange(event, page + 1)}
                    disabled={page >= Math.ceil(pagination.total / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    <ArrowForwardIosIcon />
                </IconButton>
            </Box>
        </CustomPagination>
    );
};

const SORT_FIELDS = {
    sn: "SN",
    name: "NAME",
    power: "BATTERY_PERCENTAGE",
    mostRecentUpload: "MOST_RECENT_UPLOAD_AT",
    stFirmwareRevision: "ST_FIRMWARE_REVISION",
    espFirmwareRevision: "ESP_FIRMWARE_REVISION",
    hardwareRevision: "HARDWARE_REVISION",
    wifiRssi: "WIFI_RSSI",
    firstConnectedAt: "FIRST_CONNECTED_AT",
    snFirstConnectedAt: "SN_FIRST_CONNECTED_AT",
    frozenAt: "FROZEN_AT",
};

const SORT_DIRS = {
    ascend: "ASC",
    descend: "DESC",
};

const useStyles = styled({
    monitorNameAndEdit: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "4px",
    },
});

const PetQCheckRulesTable = ({
    data = [],
    loading = false,
    columnsFn,
    pagination,
    updateParentState,
    isupdateTable,
    onUpdateRules,
    revisionOptions: {
        existingStFirmwareRevisions = [],
        existingEspFirmwareRevisions = [],
        existingHardwareRevisions = [],
    } = {},
    ...props
}) => {
    const styles = useStyles();
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setisLoading] = useState(true);
    const [checked, setChecked] = useState([]);

    useEffect(() => {
    }, [setChecked])


    const timezone = 'UTC';  // Assuming a timezone value, replace it with your logic

    const formatDateBySelectedTimezone = (date) =>
        moment(date + "Z")
            .tz(timezone)
            .format("YYYY-MM-DDTHH:mm:ss Z");

    const getDirectionFromSortString = (sortString) => {
        const sortSuffixesRegex = sortString.match(
            /.*(?:(?<ascend>_ASC)|(?<descend>_DESC))/
        );
        // console.log("sortSuffixesRegexsortSuffixesRegex", sortSuffixesRegex)
        if (sortSuffixesRegex.groups?.ascend) return "ascend";
        if (sortSuffixesRegex.groups?.descend) return "descend";
        return null;
    };

    const getDefaultSortOrderForColumn = (colIndex) => {
        // console.log("colIndexcolIndexcolIndexcolIndexcolIndex", colIndex)
        // console.log("searchParams.get", searchParams.get("sort"))
        const appliedSort = searchParams.get("sort") ?? "SN_ASC";
        const columnSortField = SORT_FIELDS[colIndex];
        // console.log("columnSortFieldcolumnSortFieldcolumnSortField", columnSortField, appliedSort)
        if (!appliedSort.includes(columnSortField)) return null;
        // console.log("appliedSortappliedSortappliedSort", appliedSort)
        const direction = getDirectionFromSortString(appliedSort);
        // console.log("directiondirectiondirectiondirection", direction)
        if (!direction) return null;
        return direction;
    };

    const forwardTableChangeToSearchParams = (pagination, sorter) => {
        // console.log("asdasdasd", pagination, sorter)
        const getSort = (sorter) => {
            if (!(sorter.field && sorter.order)) {
                return undefined;
            }
            return SORT_FIELDS[sorter.field] + "_" + SORT_DIRS[sorter.order];
        };
        // const getIsFrozen = (filters) => {
        //     if (filters.includes("isFrozen.true")) return true;
        //     if (filters.includes("isFrozen.false")) return false;
        //     return undefined;
        // };
        // const getIsOrphaned = (filters) => {
        //     if (filters.includes("isOrphaned.true")) return true;
        //     if (filters.includes("isOrphaned.false")) return false;
        //     return undefined;
        // };
        // const getPowerMode = (filters) => {
        //     let powerModeSearchParams = [];
        //     if (filters.includes("powerMode.AC")) {
        //         powerModeSearchParams.push("AC");
        //     }
        //     if (filters.includes("powerMode.BATTERY")) {
        //         powerModeSearchParams.push("BATTERY");
        //     }
        //     if (
        //         filters.includes("showBatteryWarning.true") ||
        //         filters.includes("showBatteryWarning.false")
        //     ) {
        //         powerModeSearchParams.push("BATTERY");
        //     }
        //     return powerModeSearchParams;
        // };
        // const getShowBatteryWarning = (filters) => {
        //     let showBatteryWarningSearchParams = [];
        //     if (filters.includes("showBatteryWarning.true")) {
        //         showBatteryWarningSearchParams.push(true);
        //     }
        //     if (filters.includes("showBatteryWarning.false")) {
        //         showBatteryWarningSearchParams.push(false);
        //     }
        //     return showBatteryWarningSearchParams;
        // };
        // const getMostRecentUploadWarning = (filters) => {
        //     if (filters.includes("mostRecentUploadWarning.true")) return true;
        //     if (filters.includes("mostRecentUploadWarning.false")) return false;
        //     return undefined;
        // };
        // const getStFirmwareRevision = (filters) => {
        //     const selectedRevisionNumbers = filters;
        //     return selectedRevisionNumbers;
        // };
        // const getEspFirmwareRevision = (filters) => {
        //     const selectedRevisionNumbers = filters;
        //     return selectedRevisionNumbers;
        // };
        // const getHardwareRevision = (filters) => {
        //     const selectedRevisionNumbers = filters;
        //     return selectedRevisionNumbers;
        // };

        const newSearchParams = {
            ...Object.fromEntries(searchParams),
            page: pagination.current,
            pageSize: pagination.pageSize,
            sort: getSort(sorter),
            // isFrozen: getIsFrozen(filters.frozenAt ?? []),
            // isOrphaned: getIsOrphaned(filters.orphanedAt ?? []),
            // powerMode: getPowerMode(filters.power ?? []),
            // showBatteryWarning: getShowBatteryWarning(filters.power ?? []),
            // mostRecentUploadWarning: getMostRecentUploadWarning(
            //     filters.mostRecentUpload ?? []
            // ),
            // stFirmwareRevision: getStFirmwareRevision(
            //     filters.stFirmwareRevision ?? []
            // ),
            // espFirmwareRevision: getEspFirmwareRevision(
            //     filters.espFirmwareRevision ?? []
            // ),
            // hardwareRevision: getHardwareRevision(
            //     filters.hardwareRevision ?? []
            // ),
        };

        function removeUndefinedSearchParams() {
            Object.entries(newSearchParams).forEach(([key, value]) => {
                if (value === undefined) delete newSearchParams[key];
            });
        }
        removeUndefinedSearchParams();

        function resetPageToOneIfNeeded() {
            if (Number(searchParams.get("page")) !== pagination.current) return;
            newSearchParams.page = 1;
        }
        resetPageToOneIfNeeded();
        setSearchParams(newSearchParams);
    };

    
    const handleChange = (e, id) => {

        if (!checked.includes(e.target.name)) {
            setChecked([...checked, e.target.name]);
            onUpdateRules([...checked, e.target.name])
        } else {
            setChecked(checked.filter((item) => item !== e.target.name));
            onUpdateRules(checked.filter((item) => item !== e.target.name))
        }
        
    };


    let columns = [
        {
            title: "Action",
            dataIndex: "id",
            key: "id",
            width: 150,
            render: function renderActionButton(id, record) {
                return (
                    <Box display="flex" alignItems="center" gap={1}>

                        <EditPetQCheckButton
                            selectedRule={record}
                            updateParentState={updateParentState}
                            isupdateTable={isupdateTable}
                        />
                        <Checkbox checked={checked.includes(record.id)} onChange={e => [handleChange(e)]}
                            value={record.id} name={record.id}></Checkbox>

                    </Box>
                );
            },
        },
        {
            title: "Report ID",
            dataIndex: "report_id",
            key: "report_id",
            width: 300,
        },
        {
            title: "Segment ID",
            dataIndex: "segment_id",
        },
        {
            title: "Sub Section ID",
            dataIndex: "sub_section_id",
        },
        {
            title: "Insight ID",
            dataIndex: "insight_id",

        },
        {
            title: "Section ID",
            dataIndex: "section_id",

        },
        {
            title: "Output Attribute 1 Name",
            dataIndex: "result_1_type",

        },
        {
            title: "Output Attribute 1 Value",
            dataIndex: "result_1_value",
            // render: function MachineSerial(serial, record) {
            //     return (
            //         <Tooltip title={record.result_1_value}>
            //         <span className="table-cell-trucate">{record.result_1_value.substring(0, 50)}</span>
            //       </Tooltip>
            //     );
            // },

        },
        {
            title: "Output Attribute 2 Name",
            dataIndex: "result_2_type",

        },
        {
            title: "Output Attribute 2 Value",
            dataIndex: "result_2_value",

        },
        {
            title: "Output Attribute 3 Name",
            dataIndex: "result_3_type",

        },
        {
            title: "Output Attribute 3 Value",
            dataIndex: "result_3_value",

        },

        {
            title: "Input Attribute 1 Name",
            dataIndex: "variable1_name",

        },
        {
            title: "Input Attribute 1 Value",
            dataIndex: "variable1_value",

        },
        {
            title: "Input Attribute 2 Name",
            dataIndex: "variable2_name",

        },
        {
            title: "Input Attribute 2 Value",
            dataIndex: "variable2_value",

        },
        {
            title: "Input Attribute 3 Name",
            dataIndex: "variable3_name",

        },
        {
            title: "Input Attribute 3 Value",
            dataIndex: "variable3_value",

        },
        {
            title: "Input Attribute 4 Name",
            dataIndex: "variable4_name",

        },
        {
            title: "Input Attribute 4 Value",
            dataIndex: "variable4_value",

        },
        {
            title: "Input Attribute 5 Name",
            dataIndex: "variable5_name",

        },
        {
            title: "Input Attribute 5 Value",
            dataIndex: "variable5_value",

        },
        {
            title: "Input Attribute 6 Name",
            dataIndex: "variable6_name",

        },
        {
            title: "Input Attribute 6 Value",
            dataIndex: "variable6_value",

        },
        {
            title: "Input Attribute 7 Name",
            dataIndex: "variable7_name",

        },
        {
            title: "Input Attribute 7 Value",
            dataIndex: "variable7_value",

        },
        {
            title: "Input Attribute 8 Name",
            dataIndex: "variable8_name",

        },
        {
            title: "Input Attribute 8 Value",
            dataIndex: "variable8_value",

        },
        {
            title: "Input Attribute 9 Name",
            dataIndex: "variable9_name",

        },
        {
            title: "Input Attribute 9 Value",
            dataIndex: "variable9_value",

        },
        {
            title: "Input Attribute 10 Name",
            dataIndex: "variable10_name",

        },
        {
            title: "Input Attribute 10 Value",
            dataIndex: "variable10_value",

        },
        {
            title: "Input Attribute 11 Name",
            dataIndex: "variable11_name",

        },
        {
            title: "Input Attribute 11 Value",
            dataIndex: "variable11_value",

        },
        {
            title: "Input Attribute 12 Name",
            dataIndex: "variable12_name",

        },
        {
            title: "Input Attribute 12 Value",
            dataIndex: "variable12_value",

        },
        {
            title: "Variable Value",
            dataIndex: "variable_value",

        }
    ];

    if (columnsFn) {
        columns = columnsFn(columns);
    }
    const defaultPagination = {
        position: ["topRight", "bottomRight"],
        defaultCurrent: 1,
        defaultPageSize: 50,
        showSizeChanger: true,
        showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
    };
    const tablePagination =
        pagination === false
            ? false
            : {
                ...defaultPagination,
                ...pagination,
            };
    return (
        <FullWidthPaper>
            <FullWidthTableContainer>

                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <StyledTableCell key={column.key}>
                                    {column.sorter ? (
                                        <TableSortLabel
                                            active={true}
                                        // direction={getDefaultSortOrderForColumn(column.dataIndex) || 'ascend'}
                                        // onClick={() => forwardTableChangeToSearchParams(pagination, column.sorter)}
                                        // onChange={forwardTableChangeToSearchParams}
                                        >
                                            {column.title}
                                        </TableSortLabel>
                                    ) : (
                                        column.title
                                    )}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow key={row.id}>

                                {columns.map((column) => (
                                    <TableCell key={column.key}>
                                        {column.render ? column.render(row[column.dataIndex], row) : row[column.dataIndex]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {data?.length === 0 &&
                    <Typography variant="h6" style={{ marginLeft: "640px", width: "50%", padding: "10px" }}> No Data Found </Typography>
                }
            </FullWidthTableContainer>
            {pagination !== false && (
                <CustomTablePagination
                    count={data.length}
                    rowsPerPage={pagination.pageSize}
                    page={pagination.current - 1}
                    onPageChange={(event, newPage) => forwardTableChangeToSearchParams({ current: newPage + 1, pageSize: pagination.pageSize }, {}, {})}
                    onRowsPerPageChange={(event) => forwardTableChangeToSearchParams({ current: 1, pageSize: parseInt(event.target.value, 10) }, {}, {})}
                    rowsPerPageOptions={[10, 25, 50]}
                    labelRowsPerPage="View"
                    labelDisplayedRows={({ from, to, count }) => (
                        <Box display="flex" alignItems="center">
                            <Box>{`Found ${count.toLocaleString()} Records`}</Box>
                            <Box display="flex" alignItems="center" mx={2}>
                                <WatchLaterIcon fontSize="small" />
                                {/* <Box mx={1}>{`${from} - ${to}`}</Box> */}
                                <Box mx={1}>{`${from} - ${Math.ceil(from + 49)}`}</Box>
                            </Box>
                        </Box>
                    )}
                    pagination={pagination}
                />
            )}
        </FullWidthPaper>
    );
};

export default PetQCheckRulesTable;
