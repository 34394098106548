import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  CircularProgress,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Collapse,
  Button,
  Tooltip,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/system";
import { FileCopy as FileCopyIcon } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../api/axios/axiosInstance";
import moment from "moment-timezone";
import LoadingSpinner from "../../LoadingSpinner/component";
import { useVideoSearchParams } from "../../../utils/useVideoSearchParams";
import SessionIDSearch from "../../../utils/SessionIDSearch";
import { toast, ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import PersonIcon from "@mui/icons-material/Person";
import Pagination from "../../../utils/Pagination";
import { CopyToClipboard } from "react-copy-to-clipboard";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTimezone } from "../../../utils/context/Timezone";

const useTableStyles = styled((theme) => ({
  noData: {
    opacity: 0.4,
    fontStyle: "italic",
  },
  ul: {
    margin: 0,
    paddingLeft: "1rem",
  },
  header: {
    fontWeight: "bold",
    backgroundColor: theme.palette.grey[200],
  },
  searchBox: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: "0 10px",
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
    flexGrow: 1,
  },
  searchIcon: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    color: theme.palette.common.white,
    flex: 1,
  },
  filterIcon: {
    marginLeft: "auto",
  },
  titleRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
}));

const EventRow = ({ event, source }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
    video_download(event);
    get_label_file(event);
  };
  const { dogId } = useParams();
  const petId = dogId;
  const fileInputRef = useRef();
  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";
  const format = `${dateFormat} ${timeFormat}`;
  const [videoURL, setVideoURL] = React.useState();
  const [audioURL, setAudioURL] = React.useState();
  const [error, setError] = React.useState();
  const [sensorData, setsensorData] = useState(null);
  const [isLoading, setisLoading] = React.useState(true);
  const [isLoadingSensorCSV, setisLoadingSensorCSV] = React.useState(true);
  const [csvData, setCsvData] = useState(null);
  const [isLoadingCSV, setisLoadingCSV] = React.useState(true);
  const [isenabledUpload, setisenabledUpload] = React.useState(true);
  const hasLabels = event.hasLabels;
  // TOTO: Remove Z addition after explicitly UTC times are provided on the API

  const CopyToClipboardButton = ({ textToCopy, testId }) => (
    <CopyToClipboard text={textToCopy}>
      <Tooltip title="Copy to clipboard">
        <IconButton data-testid={testId}>
          <FileCopyIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </CopyToClipboard>
  );

  const get_label_file = (event) => {
    axiosInstance
      .get(
        "/get-dog-collar-label-file-download?event_id=" +
        event.session_ids[0].trim() +
        "&startTime=" +
        event.startTime
      )
      .then((response, error) => {
        setisLoadingCSV(false);
        if (error) {
          setError(error);

          return;
        }
        if (response.data.data === "Label File Not Available") {
          setCsvData(null);
          setisenabledUpload(false);
        } else {
          setCsvData(response.data.data);
          setisenabledUpload(true);
        }
      })
      .catch((error) => {
        setisLoadingCSV(false);
        setisenabledUpload(false);
        toast.error("[API Error]: Request Failed with Status Code 500", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      });
  };

  const video_download = (event) => {
    axiosInstance
      .get(
        "/get-dog-collar-audio-video-download?event_id=" +
        event.id +
        "&video_url=" +
        event.cloud_video_file_url + "&audio_url=" +
        event.audio_data_url
      )
      .then((response, error) => {
        if (error) {
          setError(error);
          setisLoading(false);
          return;
        }
        setisLoading(false);
        setVideoURL(response.data.video_url);
        setAudioURL(response.data.audio_url);
      })
      .catch((error) => {
        setisLoading(false);
        toast.error("[API Error]: Request Failed with Status Code 500", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      });
  };

  const handleUpload = (label_file) => {
    let uploaded_label_file = label_file.target.files[0];
    let device_serial_nos = JSON.stringify(event.device_serial_nos);
    let session_ids = JSON.stringify(event.session_ids);

    axiosInstance
      .post(
        "/get-dog-collar-upload-labels?pet_id=" + petId,
        {
          cloud_video_file_url: event.cloud_video_file_url,
          pet_id: petId,
          device_serial_nos: device_serial_nos,
          file_name: event.file_name,
          session_ids: session_ids,
          pet_name: event.pet_name,
          source: event.source,
          startTime: event.startTime,
          video_end_time: event.video_end_time,
          video_start_time: event.video_start_time,
          label_file: uploaded_label_file,
          label_file_name: uploaded_label_file.name,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response, error) => {
        if (error) {
          setError(error);
          setisLoading(false);
          return;
        }
        setisLoading(false);
        if (response.data.message == "File Columns Mismatch") {
          toast.error("Request Failed, File Header Mismatch", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        } else {
          toast("Label File Uploaded!", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });

          setisLoadingCSV(true);
          setisenabledUpload(true);
          get_label_file(event);
        }
      })
      .catch((error) => {
        setisLoading(false);
        toast.error("[API ERROR]: Request Failed", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      });
  };

  const date = moment(event.startTime + "Z").format(format);

  const hasEntryTime = objectHasPropertyOfType(event, "entry", "string");
  const displayEntryTime = hasEntryTime
    ? moment(event.entry + "Z").format(format)
    : "";
  const hasExitTime = objectHasPropertyOfType(event, "exit", "string");
  const displayExitTime = hasExitTime
    ? moment(event.exit + "Z").format(format)
    : "";

  const hasEliminationStart = objectHasPropertyOfType(
    event,
    "video_start_time",
    "string"
  );
  const displayEliminationStart = hasEliminationStart
    ? moment(event.video_start_time + "Z").format(format)
    : "";

  const hasEliminationEnd = objectHasPropertyOfType(
    event,
    "video_end_time",
    "string"
  );
  const displayEliminationEnd = hasEliminationEnd
    ? moment(event.video_end_time + "Z").format(format)
    : "";

  const handleDownloadCSV = () => {
    window.location.href = csvData.url;
  };

  const handleDownload = () => {
    window.location.href = videoURL;
  };

  const handleAudioDownload = () => {
    window.location.href = audioURL;
  };

  const handleDownloadSensorCSV = () => {
    const blob = new Blob([sensorData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = event.id + ".csv";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const handleRemoveLabelFile = () => {
    axiosInstance.get('/get-dog-collar-remove-labels?event_id=' + event.session_ids + 
    '&startTime=' + event.startTime + '&source=' + source, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }).then((response, error) => {
      if (error) {
        setError(error)
        setisLoading(false);
        return;
      }
      setisLoading(false);
      toast("Label File Removed Successfully!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setisLoadingCSV(true)
      get_label_file(event);
    }).catch((error) => {
      setisLoading(false);
    });
  }

  return (
    <>
      <TableRow>
        <TableCell>
          {hasLabels ? (
            <PersonIcon
              style={{ backgroundColor: "#ED1C25", transform: "scale(1.5)" }}
            />
          ) : (
            <span></span>
          )}
        </TableCell>
        <TableCell
          onClick={() => handleExpandClick()}
          style={{ cursor: "default" }}
        >
          {date}
        </TableCell>
        <TableCell
          style={{ marginLeft: "50px", cursor: "default" }}
          onClick={() => handleExpandClick()}
        >
          {displayEliminationStart + " - " + displayEliminationEnd}
        </TableCell>
        <TableCell
          style={{ marginLeft: "400px", cursor: "default" }}
          onClick={() => handleExpandClick()}
        >
          {event.pet_name}
        </TableCell>
        <TableCell
          style={{ marginLeft: "162px", cursor: "default" }}
          onClick={() => handleExpandClick()}
        >
          {event.source}
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleExpandClick()}
          >
            {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow style={{ backgroundColor: "#6c757d1a" }}>
        <TableCell colSpan={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box padding={3}>
              <Typography variant="body2">
                <b>Device ID:</b> {event.device_serial_nos}
              </Typography>
              <Typography
                variant="body2"
                component="div"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="body2" copyable>
                  <b>Event ID:</b>{" "}
                  {event.session_ids.map((txt) => [
                    <p style={{ marginLeft: 56 }}>
                      {txt}
                      <CopyToClipboardButton
                        textToCopy={txt}
                        testId={"tids.eventView.copyEventIdToClipboard"}
                      />
                    </p>,
                  ])}
                </Typography>
              </Typography>
              <Typography variant="body2">
                <b>UTC:</b> {date}
              </Typography>

              <Typography variant="body2">
                <b>Label File:</b>{" "}
                {isLoadingCSV ? (
                  <LoadingSpinner />
                ) : csvData ? (
                  [<a
                    onClick={handleDownloadCSV}
                    download
                    style={{ color: "#096dd9", cursor: "pointer" }}
                  >
                    {csvData.label_file_name}
                  </a>,
                  <i>(Upload Date: {moment(csvData.upload_date + "Z").format(format)})</i>,
                  <Tooltip title="Delete Label File">
                  <DeleteIcon onClick={() => handleRemoveLabelFile()} variant="contained" />
                  </Tooltip>
                  ]

                ) : (
                  <span>No data available</span>
                )}
              </Typography>
              {videoURL ? (
                <Typography variant="body2">
                  <b>Video File Name:</b> {event.file_name}
                  <CopyToClipboardButton
                    textToCopy={event.file_name}
                    testId={"tids.eventView.copyEventIdToClipboard"}
                  />
                </Typography>
              ) : (
                <span>No Video available</span>
              )}
              <Typography variant="body2">
                <b>Session Video:</b>
                {videoURL ? (
                  <a
                    onClick={handleDownload}
                    download
                    style={{ color: "#096dd9", cursor: "pointer" }}
                  >
                    Download Video
                  </a>
                ) : (
                  <span>No Video available for Download</span>
                )}
              </Typography>

              {audioURL ? (
                <Typography variant="body2">
                  <b>Audio File Name:</b> {event.audio_filename}
                  <CopyToClipboardButton
                    textToCopy={event.audio_filename}
                    testId={"tids.eventView.copyEventIdToClipboard"}
                  />
                </Typography>
              ) : (
                <span></span>
              )}
              <Typography variant="body2">
                <b>Session Audio:</b>
                {audioURL ? (
                  <a
                    onClick={handleAudioDownload}
                    download
                    style={{ color: "#096dd9", cursor: "pointer" }}
                  >
                    Download Audio
                  </a>
                ) : (
                  <span>No Audio available for Download</span>
                )}
              </Typography>

              <Typography variant="body2">
                <b>Upload:</b>
                <Tooltip title={isenabledUpload ? 'Please Delete Existing Label File ': 'Please Upload Label File'}>
                <FileUploadIcon onClick={() => fileInputRef.current.click()} variant="contained" disabled={true} />
                <input
                  onChange={handleUpload}
                  multiple={false}
                  disabled={isenabledUpload}
                  ref={fileInputRef}
                  type="file"
                  hidden
                />
                </Tooltip>
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const EventTable = ({
  events,
  isLoading,
  refreshEventList,
  page,
  setPage,
  total,
  source
}) => {
  const styles = useTableStyles();

  return (
    <Box sx={{ padding: 2 }}>
      <Pagination
        total={total}
        disabled={isLoading}
        setPage={setPage}
        pageName="eventsPage"
        smallMarginBottom
      />

      <TableContainer component={Paper}>
        <Table aria-label="event table">
          <TableHead>
            <TableRow>
              <TableCell className={styles.header}></TableCell>
              <TableCell className={styles.header}>Date</TableCell>
              <TableCell className={styles.header}>Video Start - End</TableCell>
              <TableCell className={styles.header}>Dog</TableCell>
              <TableCell className={styles.header}>Source</TableCell>
              <TableCell className={styles.header}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              events.map((event) => <EventRow key={event.id} event={event} source={source}/>)
            )}
          </TableBody>
        </Table>
        {events?.length == 0 && (
          <Typography
            variant="h6"
            style={{ marginLeft: "640px", width: "50%", padding: "10px" }}
          >
            {" "}
            No Data Found{" "}
          </Typography>
        )}
      </TableContainer>
      <Pagination
        total={total}
        disabled={isLoading}
        setPage={setPage}
        pageName="eventsPage"
        smallMarginBottom
      />
    </Box>
  );
};

const objectHasPropertyOfType = (object, property, type) => {
  return (
    Object.prototype.hasOwnProperty.call(object, property) &&
    typeof object[property] === type
  );
};

const DogCollarVideoEvents = () => {
  const timezone = useTimezone();
  const isSmallScreen = useMediaQuery("(max-width:800px)");
  const { dogId, source } = useParams();
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [eventtimezone, seteventTimezone] = useState(timezone);

  const [videoSearchParams, setVideoSearchParams] = useVideoSearchParams({
    videoName: "",
  });

  const handleSearch = (videoName) => {
    let newSearchParams = {
      ...Object.fromEntries(videoSearchParams),
      page: 1,
      videoName: videoName.trim(),
    };
    if (videoName === "") {
      delete newSearchParams["videoName"];
    }
    setIsLoading(true);
    setVideoSearchParams(newSearchParams);
  };

  useEffect(() => {
    let videoName = videoSearchParams.get("videoName");
    fetchVideoData(page, dogId, startDate, endDate, videoName);
  }, [dogId, page, videoSearchParams]);

  const fetchVideoData = async (page, dogId, startDate, endDate, videoName) => {
    localStorage.removeItem("selectedEvents")
    localStorage.removeItem("selectedVideos")
    try {
      const response = await axiosInstance.post(
        "/get-dog-collar-petvideo-events?pet_id=" + dogId,
        {
          count: 30,
          pet_id: dogId,
          source: source,
          page: page,
          from_date: startDate,
          to_date: endDate,
          videoName: videoName,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setEvents(response.data.data);
      // setTotal(response.data.pageInfo.totalPages * 30); // assuming 30 items per page
      setTotal(response.data.pageInfo.totalPages);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      toast.error("[API Error]: Request Failed with Status Code 500", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const refreshEventList = () => {
    setPage(1);
    let videoName = videoSearchParams.get("videoName");
    fetchVideoData(page, dogId, startDate, endDate, videoName);
    setIsLoading(true);
  };

  const [value, setValue] = React.useState(new Date());

  const handleStartDateChange = (date) => {
    if (date != null && date.length > 0) {
      const start = moment(date[0]).tz(eventtimezone).format("YYYY-MM-DD");
      setStartDate(start);
      const end = moment(date[1]).tz(eventtimezone).format("YYYY-MM-DD");
      setEndDate(end);
      setValue(date);
    } else {
      setValue(new Date());
      setStartDate(null);
      setEndDate(null);
    }
  };

  return (
    <Box>
      <br></br>

      <SessionIDSearch
        onSearch={handleSearch}
        defaultValue={videoSearchParams.get("videoName") ?? ""}
        placeholder={"Input Video Name"}
      />

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          justifyContent: isSmallScreen ? "center" : "end",
          alignItems: "center",
          gap: 1,
          marginTop: isSmallScreen ? 0 : -3.5,
        }}
      >
        <Button key="downloadVideo" onClick={() => refreshEventList(page)}>
          Reload
        </Button>
        <DateRangePicker value={value} onChange={handleStartDateChange} />
        <Button key="filter" onClick={() => refreshEventList(page)}>
          Filter
        </Button>
      </Box>
      <EventTable
        events={events}
        isLoading={isLoading}
        refreshEventList={refreshEventList}
        page={page}
        setPage={setPage}
        total={total}
        source={source}
      />
      <ToastContainer />
    </Box>
  );
};

export default DogCollarVideoEvents;
